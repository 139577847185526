import { Link } from "@reach/router";
import Button from "components/Button";
import InputText from "components/InputText";
import Wrapper from "components/Wrapper";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useMutation } from "urql";
import { FieldLocale } from "utils/common/locale";
import { recoverPasswordMutation } from "utils/queries/user";
import { routes } from "utils/routes";
import SEO from "components/SEO";
import { useTranslation } from "hooks/useTranslation";

interface Props {
  pageContext: {
    defaultLocale: string;
    allPathLocales: FieldLocale<string>[];
    pagePath: string;
  };
}

/**
 * Recover password page wrapper
 * Just to allow useContext usage inside
 */
const RecoverPasswordPage: React.SFC<Props> = ({ pageContext }) => {
  return <RecoverPasswordForm pagePath={pageContext.pagePath} />;
};

/**
 * Handle recover password process
 */
const RecoverPasswordForm = ({ pagePath }: { pagePath: string }) => {
  // State variables
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");

  // Mutations
  const [{}, execute] = useMutation(recoverPasswordMutation);

  // Context
  const { formatMessage } = useTranslation();

  /**
   * Start the password recover process
   * In case of error populate the error box
   */
  const handleRecoverPassword = async (e: FormEvent) => {
    e.preventDefault();
    const variables = { email };
    const response = await execute(variables);
    const data = response.data;
    if (data && data.customerRecover.customer) {
      setInfo("You'll receive a reset password email shortly.");
      setError("");
    } else if (
      !data ||
      !data.customerRecover ||
      (data.customerRecover.customerUserErrors &&
        data.customerRecover.customerUserErrors.length > 0) ||
      response.error
    ) {
      setInfo("");
      setError("We encountered an error while trying to recover your password");
    }
  };

  return (
    <Container>
      <SEO
        title={formatMessage({ id: "SEO.recoverPasswordTitle" })}
        link={[
          { rel: "canonical", href: "https://ruedesmille.com" + pagePath }
        ]}
      />
      <Wrapper size="small">
        <RecoverPassword onSubmit={handleRecoverPassword}>
          <Title>
            <FormattedMessage id="label.recoverPassword" />
          </Title>
          <div>
            <InputText
              type="text"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
          </div>
          {info ? <InfoBox>{info}</InfoBox> : null}
          {error ? <ErrorBox>{error}</ErrorBox> : null}
          <ActionContainer>
            <Button type="submit">
              <FormattedMessage id="label.recoverPassword" />
            </Button>
            <LinkContainer>
              <Link to={routes.login}>
                <FormattedMessage id="label.orLogin" />
              </Link>
            </LinkContainer>
          </ActionContainer>
        </RecoverPassword>

        <Signup>
          <Title>
            <FormattedMessage id="label.createAccount" />
          </Title>
          <Description>
            <FormattedMessage id="label.createAccountDescription" />
          </Description>
          <LinkButtonContainer>
            <Link to={routes.signup}>
              <FormattedMessage id="label.signup" />
            </Link>
          </LinkButtonContainer>
        </Signup>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 40px;
  ${Wrapper} {
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }
  }
`;

const RecoverPassword = styled.form`
  width: 50%;
  flex: 0 0 50%;
  padding: 50px 80px 50px 0;
  border-right: 1px solid #ededed;
  @media (max-width: 1000px) {
    padding: 50px 40px 50px 20px;
  }
  @media (max-width: 800px) {
    padding: 50px 20px 50px 0px;
  }
  @media (max-width: 700px) {
    border-right: none;
    border-bottom: 1px solid #ededed;
    width: 100%;
    padding: 40px 20px;
  }
  @media (max-width: 500px) {
    padding: 0 0 40px 0;
  }
`;

const Signup = styled.div`
  width: 50%;
  flex: 0 0 50%;
  padding: 50px 0 50px 80px;
  @media (max-width: 1000px) {
    padding: 50px 20px 50px 40px;
  }
  @media (max-width: 800px) {
    padding: 50px 0px 50px 20px;
  }
  @media (max-width: 700px) {
    border-right: none;
    width: 100%;
    padding: 40px 20px;
  }
  @media (max-width: 500px) {
    padding: 40px 0 0 0;
  }
`;

const Title = styled.div`
  font-size: 22px;
  color: #977b2b;
  letter-spacing: 0.16em;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 500px) {
    font-size: 18px;
  }
  @media (max-width: 400px) {
    text-align: center;
  }
`;

const Description = styled.div`
  width: 100%;
  font-size: 14px;
  color: #706f6f;
  line-height: 1.8em;
  letter-spacing: 0.06em;
  @media (max-width: 400px) {
    text-align: center;
  }
`;

const LinkButtonContainer = styled.div`
  margin-top: 30px;
  @media (max-width: 400px) {
    text-align: center;
  }
  a {
    display: inline-block;
    background-color: #977b2b;
    outline: inherit;
    font-size: 14px;
    letter-spacing: 0.16em;
    font-weight: 600;
    width: 100%;
    max-width: 220px;
    text-transform: uppercase;
    font-family: soleil, sans-serif;
    transition: 0.3s all;
    color: #fff;
    width: 100%;
    height: 50px;
    text-align: center;
    text-decoration: none;
    padding-top: 13px;
    &:hover {
      cursor: pointer;
      background-color: #806724;
    }
  }
`;
const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;
const LinkContainer = styled.div`
  background-color: none;
  border: none;
  font-family: soleil, sans-serif;
  margin: 0 10px;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0.04em;
  white-space: nowrap;

  a {
    color: #333;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ErrorBox = styled.div`
  color: #dd4f4f;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-bottom: 30px;
  box-sizing: border-box;
  @media (max-width: 400px) {
    text-align: center;
  }
`;
const InfoBox = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-bottom: 30px;
  box-sizing: border-box;
  @media (max-width: 400px) {
    text-align: center;
  }
`;

export default RecoverPasswordPage;
